import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { useRecoilState } from "recoil";
import { resultModalState } from "../../store/resultModal";

const ResultModal = () => {
  const [resultModal, setResultModal] = useRecoilState(resultModalState);

  const { open, status, message } = resultModal;

  const onClose = () => {
    setResultModal((prev) => ({ ...prev, open: false }));
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="result-modal-title"
      aria-describedby="result-modal-description"
      sx={{ backgroundColor: "rgba(16, 25, 40, 0.7)" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          width: "400px",
          borderRadius: "4px",
          p: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {status === "success" ? (
          <CheckCircleIcon color="success" sx={{ fontSize: 48, mb: 2 }} />
        ) : status === "error" ? (
          <ErrorIcon color="error" sx={{ fontSize: 48, mb: 2 }} />
        ) : (
          <CircularProgress color="success" sx={{ fontSize: 48, mb: 2 }} />
        )}
        <Typography
          id="result-modal-title"
          variant="h6"
          component="h2"
          sx={{ mb: 2 }}
        >
          {status === "success"
            ? "Success"
            : status === "error"
            ? "Error"
            : "Loading"}
        </Typography>
        <Typography id="result-modal-description" sx={{ mb: 3 }}>
          {message}
        </Typography>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};
export default ResultModal;
