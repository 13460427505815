import axios from "axios";
import { localstorageKeys } from "../services/local-storage-keys";

export const createAxiosInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  // Add axios interceptor to inject the token to requests
  instance.interceptors.request.use((request: any) => {
    const token = localStorage.getItem(localstorageKeys.token);
    if (token) {
      request.headers["Authorization"] = token;
    }

    return request;
  });

  // Add axios interceptor for generic error handling
  instance.interceptors.response.use(undefined, function (error) {
    // error.handleGlobally = genericErrorHandler(error);
    const errorMessage = error?.response?.data?.error?.message;
    const isUnauthorized = errorMessage === "unauthorized";

    if (isUnauthorized) {
      localStorage.clear();
      window.location.reload();
      return;
    }
    return Promise.reject(error);
  });
  return instance;
};
export const coreAxiosInstance = createAxiosInstance(
  process.env.REACT_APP_CORE_API_URL as string
);
export const doorAccessAxiosInstance = createAxiosInstance(
  process.env.REACT_APP_DOOR_ACCESS_URL as string
);
export const networkAxiosInestance = createAxiosInstance(
  process.env.REACT_APP_NETWORK_URL as string
);
export const coreV2AccessInstance = createAxiosInstance(
  process.env.REACT_APP_CORE_API_URL_V2 as string
);
