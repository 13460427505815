import Routes from "./routes";
import "./i18n";
import { Alert, Box, LinearProgress, Snackbar } from "@mui/material";
import { useRecoilState } from "recoil";
import { Notification, notificationsState } from "./store/notifications";
import { LoadingState } from "./store/global-loading";
import ResultModal from "./components/ResultModal";

function App() {
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [loading] = useRecoilState(LoadingState);

  const handleClose = (index: number, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    let copyOfNotifications = [...notifications];
    copyOfNotifications.splice(index, 1);
    setNotifications(copyOfNotifications);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {loading && <LinearProgress />}
      <Routes />
      {notifications &&
        notifications.length > 0 &&
        notifications.map((notification: Notification, i: number) => (
          <Snackbar
            key={i}
            open={true}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={(e, r) => handleClose(i, r)}
            sx={{
              backgroundColor: "transparent",
            }}
          >
            <Alert
              onClose={() => handleClose(i)}
              severity={notification.type}
              sx={{ width: "100%" }}
              variant="filled"
            >
              {notification.message}
            </Alert>
          </Snackbar>
        ))}
      <ResultModal />
    </Box>
  );
}

export default App;
