import { atom } from 'recoil';

interface ResultModalState {
  open: boolean;
  status: 'success' | 'error' | 'loading';
  message: string;
}

export const resultModalState = atom<ResultModalState>({
  key: 'resultModalState',
  default: {
    open: false,
    status: 'success',
    message: '',
  },
});
